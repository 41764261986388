var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.onSubmit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1",
        },
        [
          _vm._m(0),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "form-row" }, [
              _c("div", { staticClass: "col-md-6" }, [
                _c("label", [_vm._v("Client ID")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formBling.client_id,
                      expression: "formBling.client_id",
                    },
                  ],
                  staticClass: "form-control",
                  class: { "is-invalid": _vm.$v.formBling.client_id.$error },
                  attrs: { type: "text" },
                  domProps: { value: _vm.formBling.client_id },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formBling, "client_id", $event.target.value)
                    },
                  },
                }),
              ]),
              _c("div", { staticClass: "col-md-6" }, [
                _c("label", [_vm._v("Client Secret")]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formBling.client_secret,
                      expression: "formBling.client_secret",
                    },
                  ],
                  staticClass: "form-control",
                  class: {
                    "is-invalid": _vm.$v.formBling.client_secret.$error,
                  },
                  attrs: { type: "text" },
                  domProps: { value: _vm.formBling.client_secret },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.formBling,
                        "client_secret",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "form-row mt-1 justify-content-end" }, [
        _c("div", { staticClass: "col-md-3" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-block btn-success",
              attrs: { type: "submit", disabled: _vm.submitted },
            },
            [
              _vm.submitted
                ? _c(
                    "div",
                    [
                      _c("b-spinner", {
                        attrs: { small: "", variant: "light" },
                      }),
                    ],
                    1
                  )
                : _c("span", [_vm._v("Salvar")]),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header mb-2" }, [
      _c("h4", { staticClass: "mb-0" }, [_vm._v(" Bling ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }