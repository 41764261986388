<template>
  <form @submit.prevent="onSubmit">
    <div
      class="card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1"
    >
      <div class="card-header mb-2">
        <h4 class="mb-0">
          Bling
        </h4>
      </div>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-6">
            <label>Client ID</label>
            <input
              class="form-control"
              type="text"
              v-model="formBling.client_id"
              :class="{ 'is-invalid': $v.formBling.client_id.$error }"
            />
          </div>
          <div class="col-md-6">
            <label>Client Secret</label>
            <input
              class="form-control"
              type="text"
              v-model="formBling.client_secret"
              :class="{ 'is-invalid': $v.formBling.client_secret.$error }"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="form-row mt-1 justify-content-end">
      <div class="col-md-3">
        <button type="submit" class="btn btn-block btn-success" :disabled="submitted">
          <div v-if="submitted">
            <b-spinner small variant="light" />
          </div>
          <span v-else>Salvar</span>
        </button>
      </div>
    </div>  
  </form>
</template>

<script>
import {
  BTabs,
  BForm,
  BTab,
  BCard,
  BCol,
  BSpinner,
  VBTooltip,
} from "bootstrap-vue";
import { required } from "vuelidate/lib/validators";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

export default {
  components: {
    BTabs,
    BCol,
    BForm,
    BTab,
    BCard,
    BSpinner,
    vSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      submitted: false,
      formBling: {
        client_id: "",
        client_secret: "",
      },
    }
  },
  validations: {
    formBling: {
      client_id: { required },
      client_secret: { required },
    }
  },
  methods: {
    async fetchBling() {
      try {
        await this.$store.dispatch("Config/fetchSchoolBling").then((res) => {
          this.formBling = res
        });
      } catch (error) {
        console.error("Error fetching bling:", error);
      }
    },
    async onSubmit() {
      this.$v.formBling.$touch();
      if (!this.$v.formBling.$error) {
        this.submitted = true;
        try {
          await this.$store.dispatch("Config/updateSchoolBling", this.formBling).then(() => {
            this.notifyDefault("success");
          });       
        } catch (error) {
          console.error("Error updating steps:", error);
        } finally {
          this.submitted = false;
        }
      }
    },
  },
  created() {
    this.fetchBling();
  }
};
</script>